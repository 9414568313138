import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1 className="name-title">Ramyar Daneshgar</h1>
        <p className="title-subtext">Security Engineer</p>
        <p className="viterbi-subtext" style={{ fontSize: '0.8rem', color: '#666' }}>
          University of Southern California Viterbi School of Engineering
        </p>

        {/* Contact Icons Section - placed directly below the university text */}
        <div className="contact-icons" style={{ display: 'flex', gap: '20px', justifyContent: 'center', marginTop: '20px' }}>
          <a href="mailto:ramyarda@usc.edu" aria-label="Email" style={{ color: 'black', fontSize: '2rem' }}>
            <i className="fas fa-envelope"></i>
          </a>
          <a href="https://github.com/ramyardaneshgar" target="_blank" rel="noopener noreferrer" aria-label="GitHub" style={{ color: 'black', fontSize: '2rem' }}>
            <i className="fab fa-github"></i>
          </a>
          <a href="https://tryhackme.com/r/p/securityengineer" target="_blank" rel="noopener noreferrer" aria-label="TryHackMe" style={{ color: 'black', fontSize: '2rem' }}>
            <i className="fas fa-hat-wizard"></i> {/* Example icon for TryHackMe */}
          </a>
          <a href="https://www.linkedin.com/in/ramyardaneshgar/" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn" style={{ color: 'black', fontSize: '2rem' }}>
            <i className="fab fa-linkedin"></i> {/* LinkedIn icon */}
          </a>
        </div>
      </header>
    </div>
  );
}

export default App;
